import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "533640d7-bbe2-475a-b8c7-4f6f216803bb"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader533640d7Bbe2475aB8c74f6f216803bb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader533640d7Bbe2475aB8c74f6f216803bb site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop4a32926b471e4599Bdd67bc45bae5a92 parentTag="HeaderTop4419b8d2Cf62448b814eBb2d050c4646" {...props} />}
      </div>
      {props["hero"] || <HomePageHero4da0a4e057164c019135E89d57833f02 parentTag="Hero0959c8e5Efba4b94A429B39a03dc6e69" {...props} />}
    </div>
  );
}

// id: "3d1f409a-75f5-48fb-a321-d7f7558471c9"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages3d1f409a75f548fbA321D7f7558471c9(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages3d1f409a75f548fbA321D7f7558471c9`}>
      {props.children}
    </div>
  );
}

// id: "2e0fdf95-00d5-47fa-a241-b271805b3c93"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo2e0fdf9500d547faA241B271805b3c93(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-3/logo-wide-vector.svg" className={`${parentTag || ""} DefaultLogo2e0fdf9500d547faA241B271805b3c93 logo`} alt="logo" />
  );
}

// id: "6ba5d17b-2c8a-4b42-9ea7-e049d727fc6d"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader6ba5d17b2c8a4b429ea7E049d727fc6d(props: any) {
  return (
    <ShortHeaderF4495d9988a3444381239aec8c5a8272 parentTag="MembersAreaHeader6ba5d17b2c8a4b429ea7E049d727fc6d" header-top={<DefaultHeaderTop4a32926b471e4599Bdd67bc45bae5a92 parentTag="HeaderTop79c2e2e80ec548fbA76a678688a6a760" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "3bbd7788-54f0-4ea6-9550-40293bec8da9"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label3bbd778854f04ea6955040293bec8da9(props: any) {
  return (
    <DefaultLogo2e0fdf9500d547faA241B271805b3c93 parentTag="Label3bbd778854f04ea6955040293bec8da9" {...props} />
  );
}

// id: "976e4b96-5475-4962-8fe4-f411d9ff3371"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault976e4b96547549628fe4F411d9ff3371(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault976e4b96547549628fe4F411d9ff3371 page`}>
      {props["header"] || <MembersAreaHeader6ba5d17b2c8a4b429ea7E049d727fc6d parentTag="Header87d6f91c301d43feA97c1bbaa96509cf" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="FooterE48f3ad59b3047d698dcEd09f58efee6" {...props} />}
    </div>
  );
}

// id: "19237ff2-d339-47be-9716-d662d01204ee"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage19237ff2D33947be9716D662d01204ee(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage19237ff2D33947be9716D662d01204ee page`}>
      {props["header"] || <HomeHeader533640d7Bbe2475aB8c74f6f216803bb parentTag="HeaderBae76cd5Fc044a64Ae9258b52b21e83d" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack7944c9f38c4e433dA4a2E444ca136a31", parentTag)} {...props} />}
      {props["body"] || <MarketingBody906d02d60fca44b9A9e87e8d658eab5c parentTag="BodyF46df1d7A1804e53891a1ba6d6f96bc9" postSlug="home-page-posts2" {...props} />}
      {props["quote"] || <QuoteB58ae1d2579d45d0A32315ad762a8798 />}
      {props["footer"] || <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="Footer2d6e386744fa46388998E8dde15ff4c5" {...props} />}
    </div>
  );
}

// id: "462a5877-1a91-46f9-a26c-e0192043ca8b"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage462a58771a9146f9A26cE0192043ca8b(props: any) {
  return (
    <SecondaryPage3cb06e5cCc68488393ca40fb354c2a62 parentTag="ResourcesPage462a58771a9146f9A26cE0192043ca8b" header={<SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="HeaderD77cbbac1a854fe9A7d92154387c2054" title="Resources" />} {...props} />
  );
}

// id: "2a68cb94-f274-4efa-9957-108816c33759"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage2a68cb94F2744efa9957108816c33759(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage2a68cb94F2744efa9957108816c33759 page`}>
      {props["header"] || <SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="Header361ea67eDd9f49c39d1dA53629268dfa" title="About Us" {...props} />}
      {props["body"] || <MarketingBody906d02d60fca44b9A9e87e8d658eab5c parentTag="Body34c34e3eD50f4c23Beea525c629e6c71" postSlug="about-page-posts" {...props} />}
      {props["footer"] || <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="Footer1bc4c16332b4440dB1a6B7d9c48a96f7" {...props} />}
    </div>
  );
}

// id: "c2f32762-ae8b-48a3-9b04-ab1c0c5c1c5f"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderC2f32762Ae8b48a39b04Ab1c0c5c1c5f(props: any) {
  return (
    <ShortHeaderF4495d9988a3444381239aec8c5a8272 parentTag="DefaultHeaderC2f32762Ae8b48a39b04Ab1c0c5c1c5f" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "3cb06e5c-cc68-4883-93ca-40fb354c2a62"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage3cb06e5cCc68488393ca40fb354c2a62(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage3cb06e5cCc68488393ca40fb354c2a62`}>
      {props["header"] || <SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="HeaderC8726071174f4fd2881c6756de489d57" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="Footer9231017d85104c8c869f4b064de539f7" {...props} />}
    </div>
  );
}

// id: "f0a62553-c20b-4e3d-9cc3-bed1448d7b6b"
// title: "About Us - Post Slug"
// type: :text
// key: "postSlug"
// parent_id: nil
export const PostSlugF0a62553C20b4e3d9cc3Bed1448d7b6b = "about-us-posts";

// id: "ee710927-fa1f-4a17-8d4c-4be409ee1dd5"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageEe710927Fa1f4a178d4c4be409ee1dd5(props: any) {
  return (
    <SecondaryPage3cb06e5cCc68488393ca40fb354c2a62 parentTag="JoinUsPageEe710927Fa1f4a178d4c4be409ee1dd5" header={<SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="HeaderDb3b583812ef41e6Bc8c80a358123c23" title="Join Us" />} {...props} />
  );
}

// id: "5c7dd50d-a862-459a-9369-8313a4a24d61"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href5c7dd50dA862459a93698313a4a24d61 = "/";

// id: "5a170a15-55a3-4e12-aade-b1ac0c748027"
// title: "Login Page"
// type: :html
// key: "login-page"
// parent_id: nil
export function LoginPage5a170a1555a34e12AadeB1ac0c748027(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <>
      {props["header"] || <SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="Header96250f54240545239b391c4d5a6a368c" title="Login" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="FooterF4acfd3e1d8a4f408f14E981ee03ec82" {...props} />}
    </>
  );
}

// id: "906d02d6-0fca-44b9-a9e8-7e8d658eab5c"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody906d02d60fca44b9A9e87e8d658eab5c(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody906d02d60fca44b9A9e87e8d658eab5c page-body`}>
      {props["marketing-cards"] || <MarketingCards parentTag={buildClassName("MarketingCards8692c80556e148eaBab3Eddf27fbf591", parentTag)} {...props} />}
    </div>
  );
}

// id: "929aeeda-4f3c-4420-a18b-fa54a3fdbf8d"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote929aeeda4f3c4420A18bFa54a3fdbf8d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote929aeeda4f3c4420A18bFa54a3fdbf8d`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "857c1f4c-5225-4cdb-9e8c-06e6accf5aee"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer857c1f4c52254cdb9e8c06e6accf5aee(props: any) {
  return (
    <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="Footer857c1f4c52254cdb9e8c06e6accf5aee" {...props} />
  );
}

// id: "484230e3-9941-4899-b6f5-9b7179913274"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages484230e399414899B6f59b7179913274(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages484230e399414899B6f59b7179913274`}>
      {props["header"] || <MembersAreaHeader6ba5d17b2c8a4b429ea7E049d727fc6d parentTag="Header96582bb42a6c4c5897a72195687103d2" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNavB3b129a6902a406f86cf68927b376610", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="Footer784c5ed2Fce8410789d5Cf14c68a7fb8" {...props} />}
    </div>
  );
}

// id: "90a5965a-68d0-4a98-b1a3-60bd608467af"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "4a32926b-471e-4599-bdd6-7bc45bae5a92"
export function LinkedLogo90a5965a68d04a98B1a360bd608467af(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo90a5965a68d04a98B1a360bd608467af", parentTag)} className="linked-logo" label={<DefaultLogo2e0fdf9500d547faA241B271805b3c93 parentTag="Label734b5853Dacb4993Ac0bAf63fd2f9033" alt="logo" />} url="/" {...props} />
  );
}

// id: "d77cbbac-1a85-4fe9-a7d9-2154387c2054"
// title: ""
// type: :reference
// key: "header"
// parent_id: "462a5877-1a91-46f9-a26c-e0192043ca8b"
export function HeaderD77cbbac1a854fe9A7d92154387c2054(props: any) {
  return (
    <SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="HeaderD77cbbac1a854fe9A7d92154387c2054" title="Resources" {...props} />
  );
}

// id: "d1d7adf5-aef5-4a11-9c6a-442d7e998c00"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "147d6e17-cb6b-41b8-bd4e-cbfe705c794d"
export function HeroD1d7adf5Aef54a119c6a442d7e998c00(props: any) {
  return (
    <SecondaryMarketingHero601b60e5E73b4c329943D29d8c6c845f parentTag="HeroD1d7adf5Aef54a119c6a442d7e998c00" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "34c34e3e-d50f-4c23-beea-525c629e6c71"
// title: ""
// type: :reference
// key: "body"
// parent_id: "2a68cb94-f274-4efa-9957-108816c33759"
export function Body34c34e3eD50f4c23Beea525c629e6c71(props: any) {
  return (
    <MarketingBody906d02d60fca44b9A9e87e8d658eab5c parentTag="Body34c34e3eD50f4c23Beea525c629e6c71" postSlug="about-page-posts" {...props} />
  );
}

// id: "c242da18-f6e0-4ff6-92d4-c2c01c32373a"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "f4495d99-88a3-4443-8123-9aec8c5a8272"
export function LinkedLogoC242da18F6e04ff692d4C2c01c32373a(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoC242da18F6e04ff692d4C2c01c32373a", parentTag)} label={<DefaultLogo2e0fdf9500d547faA241B271805b3c93 parentTag="LabelB0dfa95c65564195B4f00d85e8f4aa7a" alt="logo" />} {...props} />
  );
}

// id: "ede09528-31eb-4263-8912-5fd093180f6e"
// title: ""
// type: :text
// key: "title"
// parent_id: "d77cbbac-1a85-4fe9-a7d9-2154387c2054"
export const TitleEde0952831eb426389125fd093180f6e = "Resources";

// id: "86062b17-470c-4aea-8f9c-3f38b5df9fe0"
// title: "Login Page Title"
// type: :text
// key: "title"
// parent_id: "96250f54-2405-4523-9b39-1c4d5a6a368c"
export const Title86062b17470c4aea8f9c3f38b5df9fe0 = "Login";

// id: "f8d581a5-9115-4c50-85ee-afc37a378687"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterF8d581a591154c5085eeAfc37a378687(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterF8d581a591154c5085eeAfc37a378687 footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("LinksF4153171Abc24b3684a2Deb959277fe9", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo75ea4596F011461b8d5d745169950e7a />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright331f5e250d8949e6Afdd23637feb2e00", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow567f446731694e279387015eb89faede", parentTag)} {...props} />}
    </div>
  );
}

// id: "f8990c72-2327-4d67-b89f-940721af1369"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsF8990c7223274d67B89f940721af1369(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsF8990c7223274d67B89f940721af1369", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "0959c8e5-efba-4b94-a429-b39a03dc6e69"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "533640d7-bbe2-475a-b8c7-4f6f216803bb"
export function Hero0959c8e5Efba4b94A429B39a03dc6e69(props: any) {
  return (
    <HomePageHero4da0a4e057164c019135E89d57833f02 parentTag="Hero0959c8e5Efba4b94A429B39a03dc6e69" {...props} />
  );
}

// id: "f4acfd3e-1d8a-4f40-8f14-e981ee03ec82"
// title: "Login Footer"
// type: :reference
// key: "footer"
// parent_id: "5a170a15-55a3-4e12-aade-b1ac0c748027"
export function FooterF4acfd3e1d8a4f408f14E981ee03ec82(props: any) {
  return (
    <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="FooterF4acfd3e1d8a4f408f14E981ee03ec82" {...props} />
  );
}

// id: "a2bb1080-f52b-468d-a0c1-572c2f650631"
// title: ""
// type: :text
// key: "alt"
// parent_id: "2e0fdf95-00d5-47fa-a241-b271805b3c93"
export const AltA2bb1080F52b468dA0c1572c2f650631 = "logo";

// id: "b58ae1d2-579d-45d0-a323-15ad762a8798"
// title: ""
// type: :html
// key: "quote"
// parent_id: "19237ff2-d339-47be-9716-d662d01204ee"
export function QuoteB58ae1d2579d45d0A32315ad762a8798(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} QuoteB58ae1d2579d45d0A32315ad762a8798 PageQuote`}>
      <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/qLgFtj0sve8UYvTr_regular.png" className="image opacity" />
      <div className="TextContent">
        <div className="text-inner">
          Our mission is to change the world by promoting healthy intimacy through community and education.
        </div>
      </div>
    </div>
  );
}

// id: "bae76cd5-fc04-4a64-ae92-58b52b21e83d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "19237ff2-d339-47be-9716-d662d01204ee"
export function HeaderBae76cd5Fc044a64Ae9258b52b21e83d(props: any) {
  return (
    <HomeHeader533640d7Bbe2475aB8c74f6f216803bb parentTag="HeaderBae76cd5Fc044a64Ae9258b52b21e83d" {...props} />
  );
}

// id: "d00b75f6-1202-4060-8ec6-6d7a6f7349ce"
// title: ""
// type: :text
// key: "title"
// parent_id: "d1d7adf5-aef5-4a11-9c6a-442d7e998c00"
export const TitleD00b75f6120240608ec66d7a6f7349ce = "Secondary Marketing Title 2";

// id: "3b5388a4-4ddd-41bb-8509-9b0167386797"
// title: "Home Page postSlug"
// type: :text
// key: "postSlug"
// parent_id: "f46df1d7-a180-4e53-891a-1ba6d6f96bc9"
export const PostSlug3b5388a44ddd41bb85099b0167386797 = "home-page-posts2";

// id: "5dcc8363-e19e-49c4-b5dc-dfe4e7ca80b2"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages5dcc8363E19e49c4B5dcDfe4e7ca80b2(props: any) {
  return (
    <SecondaryPage3cb06e5cCc68488393ca40fb354c2a62 parentTag="SignupPages5dcc8363E19e49c4B5dcDfe4e7ca80b2" header={<SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="Header3f5e2118A8864a54A787De03086710c5" title="Join Us" />} {...props} />
  );
}

// id: "370e22e3-52c7-4174-9713-7467752d9386"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages370e22e352c7417497137467752d9386(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages370e22e352c7417497137467752d9386`}>
      {props["header"] || <MembersAreaHeader6ba5d17b2c8a4b429ea7E049d727fc6d parentTag="HeaderEebc904e3f7840d5913a089032a75ce3" {...props} />}
      {props.children}
    </div>
  );
}

// id: "f57fd266-127a-4a5d-96a8-a59b60da24ce"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "f8d581a5-9115-4c50-85ee-afc37a378687"
export function SocialLinksF57fd266127a4a5d96a8A59b60da24ce(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinksF57fd266127a4a5d96a8A59b60da24ce flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "b3b129a6-902a-406f-86cf-68927b376610"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "484230e3-9941-4899-b6f5-9b7179913274"
export function SecondaryNavB3b129a6902a406f86cf68927b376610(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNavB3b129a6902a406f86cf68927b376610", parentTag)} {...props} />
  );
}

// id: "a2d923d9-4429-4109-85ae-152a43ddbbd2"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "f8990c72-2327-4d67-b89f-940721af1369"
export const ButtonClassA2d923d94429410985ae152a43ddbbd2 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "615490fc-b25d-4a62-a0d2-ee127a090067"
// title: ""
// type: :text
// key: "title"
// parent_id: "147d6e17-cb6b-41b8-bd4e-cbfe705c794d"
export const Title615490fcB25d4a62A0d2Ee127a090067 = "Secondary Marketing Title 1";

// id: "0727215e-8b43-4d76-b589-fed35dc9aba8"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "87d6f91c-301d-43fe-a97c-1bbaa96509cf"
export const NavMenuSlug0727215e8b434d76B589Fed35dc9aba8 = "members-primary-nav";

// id: "51e16a96-1743-41d4-9ed7-c81cdaea689a"
// title: "About Page PostSlug"
// type: :text
// key: "postSlug"
// parent_id: "34c34e3e-d50f-4c23-beea-525c629e6c71"
export const PostSlug51e16a96174341d49ed7C81cdaea689a = "about-page-posts";

// id: "df268efc-3342-45a4-9775-4db12de3d6f8"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "79c2e2e8-0ec5-48fb-a76a-678688a6a760"
export const NavMenuSlugDf268efc334245a497754db12de3d6f8 = "members-primary-nav";

// id: "f4495d99-88a3-4443-8123-9aec8c5a8272"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderF4495d9988a3444381239aec8c5a8272(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderF4495d9988a3444381239aec8c5a8272 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoC242da18F6e04ff692d4C2c01c32373a", parentTag)} label={<DefaultLogo2e0fdf9500d547faA241B271805b3c93 parentTag="LabelB0dfa95c65564195B4f00d85e8f4aa7a" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation2b61fcae7e424d69B54aAb5bc1d5b791", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "a16b7e4c-9abc-4347-9c63-cbfc54de75e1"
// title: "Public Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "c2f32762-ae8b-48a3-9b04-ab1c0c5c1c5f"
export const NavMenuSlugA16b7e4c9abc43479c63Cbfc54de75e1 = "marketing-primary-nav";

// id: "99616b1b-fdd3-46ce-863a-f36e62a2bc07"
// title: ""
// type: :text
// key: "title"
// parent_id: "c2f32762-ae8b-48a3-9b04-ab1c0c5c1c5f"
export const Title99616b1bFdd346ce863aF36e62a2bc07 = null;

// id: "75ea4596-f011-461b-8d5d-745169950e7a"
// title: ""
// type: :html
// key: "logo"
// parent_id: "f8d581a5-9115-4c50-85ee-afc37a378687"
export function Logo75ea4596F011461b8d5d745169950e7a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-3/logo-wide-vector.svg" alt="SP Salem logo" className={`${parentTag || ""} Logo75ea4596F011461b8d5d745169950e7a logo`} />
  );
}

// id: "67416c1c-bb9e-421c-a453-005b2b63613a"
// title: ""
// type: :text
// key: "label"
// parent_id: "8fb85f66-9f05-44f5-bbd4-1102a423d038"
export const Label67416c1cBb9e421cA453005b2b63613a = "Join Us";

// id: "7e08cf83-dbd6-43b5-bc5b-62ca9236ebf3"
// title: ""
// type: :text
// key: "class"
// parent_id: "90a5965a-68d0-4a98-b1a3-60bd608467af"
export const Class7e08cf83Dbd643b5Bc5b62ca9236ebf3 = "linked-logo";

// id: "d89dd62f-91bb-4660-8295-3c09ea503a17"
// title: ""
// type: :text
// key: "title"
// parent_id: "3f5e2118-a886-4a54-a787-de03086710c5"
export const TitleD89dd62f91bb466082953c09ea503a17 = "Join Us";

// id: "2490953d-1e1c-4eaa-8463-c79c1b1f0788"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage2490953d1e1c4eaa8463C79c1b1f0788(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage2490953d1e1c4eaa8463C79c1b1f0788`}>
      {props["header"] || <SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="Header297058c8C6cd484bBfe13f2bf81f3471" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="FooterBfc3f50e72384627B224C060ee1cd255" {...props} />}
    </div>
  );
}

// id: "054920e9-6e44-4043-b1d2-05562afdbfde"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "f8d581a5-9115-4c50-85ee-afc37a378687"
export function SocialLinks054920e96e444043B1d205562afdbfde(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks054920e96e444043B1d205562afdbfde flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "eebc904e-3f78-40d5-913a-089032a75ce3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "370e22e3-52c7-4174-9713-7467752d9386"
export function HeaderEebc904e3f7840d5913a089032a75ce3(props: any) {
  return (
    <MembersAreaHeader6ba5d17b2c8a4b429ea7E049d727fc6d parentTag="HeaderEebc904e3f7840d5913a089032a75ce3" {...props} />
  );
}

// id: "567f4467-3169-4e27-9387-015eb89faede"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "f8d581a5-9115-4c50-85ee-afc37a378687"
export function Groupflow567f446731694e279387015eb89faede(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow567f446731694e279387015eb89faede", parentTag)} {...props} />
  );
}

// id: "1bc4c163-32b4-440d-b1a6-b7d9c48a96f7"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "2a68cb94-f274-4efa-9957-108816c33759"
export function Footer1bc4c16332b4440dB1a6B7d9c48a96f7(props: any) {
  return (
    <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="Footer1bc4c16332b4440dB1a6B7d9c48a96f7" {...props} />
  );
}

// id: "87d6f91c-301d-43fe-a97c-1bbaa96509cf"
// title: ""
// type: :reference
// key: "header"
// parent_id: "976e4b96-5475-4962-8fe4-f411d9ff3371"
export function Header87d6f91c301d43feA97c1bbaa96509cf(props: any) {
  return (
    <MembersAreaHeader6ba5d17b2c8a4b429ea7E049d727fc6d parentTag="Header87d6f91c301d43feA97c1bbaa96509cf" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "4b7de861-11de-4edd-9078-2e760b8fd742"
// title: ""
// type: :text
// key: "title"
// parent_id: "297058c8-c6cd-484b-bfe1-3f2bf81f3471"
export const Title4b7de86111de4edd90782e760b8fd742 = "Public Events";

// id: "39045dbc-7d09-4f1b-ab6d-7ae409638b34"
// title: ""
// type: :text
// key: "class"
// parent_id: "5bce5f1d-4ecd-454b-8e24-2dfd60327fc7"
export const Class39045dbc7d094f1bAb6d7ae409638b34 = "navigation";

// id: "147d6e17-cb6b-41b8-bd4e-cbfe705c794d"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop4a32926b471e4599Bdd67bc45bae5a92 parentTag="HeaderTopB093bd3d917142bbB58a3fdcc1a09481" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero601b60e5E73b4c329943D29d8c6c845f parentTag="HeroD1d7adf5Aef54a119c6a442d7e998c00" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "7944c9f3-8c4e-433d-a4a2-e444ca136a31"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "19237ff2-d339-47be-9716-d662d01204ee"
export function WelcomeBack7944c9f38c4e433dA4a2E444ca136a31(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack7944c9f38c4e433dA4a2E444ca136a31", parentTag)} {...props} />
  );
}

// id: "bfc3f50e-7238-4627-b224-c060ee1cd255"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "2490953d-1e1c-4eaa-8463-c79c1b1f0788"
export function FooterBfc3f50e72384627B224C060ee1cd255(props: any) {
  return (
    <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="FooterBfc3f50e72384627B224C060ee1cd255" {...props} />
  );
}

// id: "96582bb4-2a6c-4c58-97a7-2195687103d2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "484230e3-9941-4899-b6f5-9b7179913274"
export function Header96582bb42a6c4c5897a72195687103d2(props: any) {
  return (
    <MembersAreaHeader6ba5d17b2c8a4b429ea7E049d727fc6d parentTag="Header96582bb42a6c4c5897a72195687103d2" {...props} />
  );
}

// id: "331f5e25-0d89-49e6-afdd-23637feb2e00"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "f8d581a5-9115-4c50-85ee-afc37a378687"
export function Copyright331f5e250d8949e6Afdd23637feb2e00(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright331f5e250d8949e6Afdd23637feb2e00", parentTag)} {...props} />
  );
}

// id: "96250f54-2405-4523-9b39-1c4d5a6a368c"
// title: "Login Header"
// type: :reference
// key: "header"
// parent_id: "5a170a15-55a3-4e12-aade-b1ac0c748027"
export function Header96250f54240545239b391c4d5a6a368c(props: any) {
  return (
    <SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="Header96250f54240545239b391c4d5a6a368c" title="Login" {...props} />
  );
}

// id: "41b2d9e9-8dd8-4e7e-8413-679825aa6d47"
// title: ""
// type: :text
// key: "title"
// parent_id: "db3b5838-12ef-41e6-bc8c-80a358123c23"
export const Title41b2d9e98dd84e7e8413679825aa6d47 = "Join Us";

// id: "37608792-a9ce-4b73-91c9-a575b85302ef"
// title: ""
// type: :text
// key: "href"
// parent_id: "8fb85f66-9f05-44f5-bbd4-1102a423d038"
export const Href37608792A9ce4b7391c9A575b85302ef = "/join";

// id: "4a32926b-471e-4599-bdd6-7bc45bae5a92"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop4a32926b471e4599Bdd67bc45bae5a92(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop4a32926b471e4599Bdd67bc45bae5a92 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo90a5965a68d04a98B1a360bd608467af", parentTag)} className="linked-logo" label={<DefaultLogo2e0fdf9500d547faA241B271805b3c93 parentTag="Label734b5853Dacb4993Ac0bAf63fd2f9033" alt="logo" />} url="/" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation5bce5f1d4ecd454b8e242dfd60327fc7", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "2b61fcae-7e42-4d69-b54a-ab5bc1d5b791"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "f4495d99-88a3-4443-8123-9aec8c5a8272"
export function Navigation2b61fcae7e424d69B54aAb5bc1d5b791(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation2b61fcae7e424d69B54aAb5bc1d5b791", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "8fb85f66-9f05-44f5-bbd4-1102a423d038"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "4da0a4e0-5716-4c01-9135-e89d57833f02"
export function Button8fb85f669f0544f5Bbd41102a423d038(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button8fb85f669f0544f5Bbd41102a423d038", parentTag)} label="Join Us" href="/join" {...props} />
  );
}

// id: "e48f3ad5-9b30-47d6-98dc-ed09f58efee6"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "976e4b96-5475-4962-8fe4-f411d9ff3371"
export function FooterE48f3ad59b3047d698dcEd09f58efee6(props: any) {
  return (
    <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="FooterE48f3ad59b3047d698dcEd09f58efee6" {...props} />
  );
}

// id: "5111cbc0-e3ea-4e63-92d3-38e9a91ab0c6"
// title: ""
// type: :text
// key: "title"
// parent_id: "601b60e5-e73b-4c32-9943-d29d8c6c845f"
export const Title5111cbc0E3ea4e6392d338e9a91ab0c6 = "Default Title";

// id: "b093bd3d-9171-42bb-b58a-3fdcc1a09481"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "147d6e17-cb6b-41b8-bd4e-cbfe705c794d"
export function HeaderTopB093bd3d917142bbB58a3fdcc1a09481(props: any) {
  return (
    <DefaultHeaderTop4a32926b471e4599Bdd67bc45bae5a92 parentTag="HeaderTopB093bd3d917142bbB58a3fdcc1a09481" {...props} />
  );
}

// id: "734b5853-dacb-4993-ac0b-af63fd2f9033"
// title: ""
// type: :reference
// key: "label"
// parent_id: "90a5965a-68d0-4a98-b1a3-60bd608467af"
export function Label734b5853Dacb4993Ac0bAf63fd2f9033(props: any) {
  return (
    <DefaultLogo2e0fdf9500d547faA241B271805b3c93 parentTag="Label734b5853Dacb4993Ac0bAf63fd2f9033" {...props} />
  );
}

// id: "57a6c466-c5c9-4508-9f88-0205d8455ff1"
// title: ""
// type: :text
// key: "title"
// parent_id: "c8726071-174f-4fd2-881c-6756de489d57"
export const Title57a6c466C5c945089f880205d8455ff1 = "Secondary Page";

// id: "601b60e5-e73b-4c32-9943-d29d8c6c845f"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero601b60e5E73b4c329943D29d8c6c845f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero601b60e5E73b4c329943D29d8c6c845f hero`}>
      <div className="title">
        {props["title"] || Title5111cbc0E3ea4e6392d338e9a91ab0c6}
      </div>
    </div>
  );
}

// id: "79c2e2e8-0ec5-48fb-a76a-678688a6a760"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "6ba5d17b-2c8a-4b42-9ea7-e049d727fc6d"
export function HeaderTop79c2e2e80ec548fbA76a678688a6a760(props: any) {
  return (
    <DefaultHeaderTop4a32926b471e4599Bdd67bc45bae5a92 parentTag="HeaderTop79c2e2e80ec548fbA76a678688a6a760" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "29a4552e-e4d9-4aa0-b339-743d2cff77f0"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "f4495d99-88a3-4443-8123-9aec8c5a8272"
export const BackgroundImage29a4552eE4d94aa0B339743d2cff77f0 = "https://mp1md-pub.s3.amazonaws.com/orgs/sp-salem/city-salem-background.image.jpg";

// id: "9231017d-8510-4c8c-869f-4b064de539f7"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "3cb06e5c-cc68-4883-93ca-40fb354c2a62"
export function Footer9231017d85104c8c869f4b064de539f7(props: any) {
  return (
    <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="Footer9231017d85104c8c869f4b064de539f7" {...props} />
  );
}

// id: "f4153171-abc2-4b36-84a2-deb959277fe9"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "f8d581a5-9115-4c50-85ee-afc37a378687"
export function LinksF4153171Abc24b3684a2Deb959277fe9(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksF4153171Abc24b3684a2Deb959277fe9", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "2d6e3867-44fa-4638-8998-e8dde15ff4c5"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "19237ff2-d339-47be-9716-d662d01204ee"
export function Footer2d6e386744fa46388998E8dde15ff4c5(props: any) {
  return (
    <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="Footer2d6e386744fa46388998E8dde15ff4c5" {...props} />
  );
}

// id: "7ed60172-a29f-402c-b83b-d1ad656e5c69"
// title: ""
// type: :text
// key: "title"
// parent_id: "361ea67e-dd9f-49c3-9d1d-a53629268dfa"
export const Title7ed60172A29f402cB83bD1ad656e5c69 = "About Us";

// id: "f0553fb1-dc5f-4c08-a1b1-e6954418f326"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "2b61fcae-7e42-4d69-b54a-ab5bc1d5b791"
export const NavMenuSlugF0553fb1Dc5f4c08A1b1E6954418f326 = "members-primary-nav";

// id: "4da0a4e0-5716-4c01-9135-e89d57833f02"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero4da0a4e057164c019135E89d57833f02(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero4da0a4e057164c019135E89d57833f02 hero`}>
      <div className="title">
        DEMO-3
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button8fb85f669f0544f5Bbd41102a423d038", parentTag)} label="Join Us" href="/join" {...props} />}
      </div>
    </div>
  );
}

// id: "c8726071-174f-4fd2-881c-6756de489d57"
// title: ""
// type: :reference
// key: "header"
// parent_id: "3cb06e5c-cc68-4883-93ca-40fb354c2a62"
export function HeaderC8726071174f4fd2881c6756de489d57(props: any) {
  return (
    <SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="HeaderC8726071174f4fd2881c6756de489d57" title="Secondary Page" {...props} />
  );
}

// id: "297058c8-c6cd-484b-bfe1-3f2bf81f3471"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2490953d-1e1c-4eaa-8463-c79c1b1f0788"
export function Header297058c8C6cd484bBfe13f2bf81f3471(props: any) {
  return (
    <SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="Header297058c8C6cd484bBfe13f2bf81f3471" title="Public Events" {...props} />
  );
}

// id: "4419b8d2-cf62-448b-814e-bb2d050c4646"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "533640d7-bbe2-475a-b8c7-4f6f216803bb"
export function HeaderTop4419b8d2Cf62448b814eBb2d050c4646(props: any) {
  return (
    <DefaultHeaderTop4a32926b471e4599Bdd67bc45bae5a92 parentTag="HeaderTop4419b8d2Cf62448b814eBb2d050c4646" {...props} />
  );
}

// id: "8692c805-56e1-48ea-bab3-eddf27fbf591"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: "906d02d6-0fca-44b9-a9e8-7e8d658eab5c"
export function MarketingCards8692c80556e148eaBab3Eddf27fbf591(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards8692c80556e148eaBab3Eddf27fbf591", parentTag)} {...props} />
  );
}

// id: "f46df1d7-a180-4e53-891a-1ba6d6f96bc9"
// title: "Home Page Body"
// type: :reference
// key: "body"
// parent_id: "19237ff2-d339-47be-9716-d662d01204ee"
export function BodyF46df1d7A1804e53891a1ba6d6f96bc9(props: any) {
  return (
    <MarketingBody906d02d60fca44b9A9e87e8d658eab5c parentTag="BodyF46df1d7A1804e53891a1ba6d6f96bc9" postSlug="home-page-posts2" {...props} />
  );
}

// id: "0da1a647-8d3e-4f43-b20a-61903aceedb8"
// title: ""
// type: :text
// key: "url"
// parent_id: "90a5965a-68d0-4a98-b1a3-60bd608467af"
export const Url0da1a6478d3e4f43B20a61903aceedb8 = "/";

// id: "3d296a08-0ec4-4a89-b0a6-1faa60295646"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "5bce5f1d-4ecd-454b-8e24-2dfd60327fc7"
export const NavMenuSlug3d296a080ec44a89B0a61faa60295646 = "marketing-primary-nav";

// id: "73eeef80-a427-485f-98b6-789a8f20c266"
// title: "Password Reset Page"
// type: :reference
// key: "password-reset-page"
// parent_id: nil
export function PasswordResetPage73eeef80A427485f98b6789a8f20c266(props: any) {
  return (
    <LoginPage5a170a1555a34e12AadeB1ac0c748027 parentTag="PasswordResetPage73eeef80A427485f98b6789a8f20c266" {...props} />
  );
}

// id: "db3b5838-12ef-41e6-bc8c-80a358123c23"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ee710927-fa1f-4a17-8d4c-4be409ee1dd5"
export function HeaderDb3b583812ef41e6Bc8c80a358123c23(props: any) {
  return (
    <SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="HeaderDb3b583812ef41e6Bc8c80a358123c23" title="Join Us" {...props} />
  );
}

// id: "5bce5f1d-4ecd-454b-8e24-2dfd60327fc7"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "4a32926b-471e-4599-bdd6-7bc45bae5a92"
export function Navigation5bce5f1d4ecd454b8e242dfd60327fc7(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation5bce5f1d4ecd454b8e242dfd60327fc7", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "3f5e2118-a886-4a54-a787-de03086710c5"
// title: ""
// type: :reference
// key: "header"
// parent_id: "5dcc8363-e19e-49c4-b5dc-dfe4e7ca80b2"
export function Header3f5e2118A8864a54A787De03086710c5(props: any) {
  return (
    <SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="Header3f5e2118A8864a54A787De03086710c5" title="Join Us" {...props} />
  );
}

// id: "784c5ed2-fce8-4107-89d5-cf14c68a7fb8"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "484230e3-9941-4899-b6f5-9b7179913274"
export function Footer784c5ed2Fce8410789d5Cf14c68a7fb8(props: any) {
  return (
    <DefaultFooterF8d581a591154c5085eeAfc37a378687 parentTag="Footer784c5ed2Fce8410789d5Cf14c68a7fb8" {...props} />
  );
}

// id: "361ea67e-dd9f-49c3-9d1d-a53629268dfa"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2a68cb94-f274-4efa-9957-108816c33759"
export function Header361ea67eDd9f49c39d1dA53629268dfa(props: any) {
  return (
    <SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d parentTag="Header361ea67eDd9f49c39d1dA53629268dfa" title="About Us" {...props} />
  );
}

// id: "b0dfa95c-6556-4195-b4f0-0d85e8f4aa7a"
// title: ""
// type: :reference
// key: "label"
// parent_id: "c242da18-f6e0-4ff6-92d4-c2c01c32373a"
export function LabelB0dfa95c65564195B4f00d85e8f4aa7a(props: any) {
  return (
    <DefaultLogo2e0fdf9500d547faA241B271805b3c93 parentTag="LabelB0dfa95c65564195B4f00d85e8f4aa7a" {...props} />
  );
}

// id: "630d15d1-fba9-4dda-9370-248109d7a8d3"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "f4153171-abc2-4b36-84a2-deb959277fe9"
export const NavMenuSlug630d15d1Fba94dda9370248109d7a8d3 = "footer-nav";

const Components = {
  HomeHeader533640d7Bbe2475aB8c74f6f216803bb,
  AdminPages3d1f409a75f548fbA321D7f7558471c9,
  DefaultLogo2e0fdf9500d547faA241B271805b3c93,
  MembersAreaHeader6ba5d17b2c8a4b429ea7E049d727fc6d,
  Label3bbd778854f04ea6955040293bec8da9,
  MembersAreaDefault976e4b96547549628fe4F411d9ff3371,
  HomePage19237ff2D33947be9716D662d01204ee,
  ResourcesPage462a58771a9146f9A26cE0192043ca8b,
  AboutUsPage2a68cb94F2744efa9957108816c33759,
  DefaultHeaderC2f32762Ae8b48a39b04Ab1c0c5c1c5f,
  SecondaryPage3cb06e5cCc68488393ca40fb354c2a62,
  PostSlugF0a62553C20b4e3d9cc3Bed1448d7b6b,
  JoinUsPageEe710927Fa1f4a178d4c4be409ee1dd5,
  Href5c7dd50dA862459a93698313a4a24d61,
  LoginPage5a170a1555a34e12AadeB1ac0c748027,
  MarketingBody906d02d60fca44b9A9e87e8d658eab5c,
  PageQuote929aeeda4f3c4420A18bFa54a3fdbf8d,
  Footer857c1f4c52254cdb9e8c06e6accf5aee,
  MemberPages484230e399414899B6f59b7179913274,
  LinkedLogo90a5965a68d04a98B1a360bd608467af,
  HeaderD77cbbac1a854fe9A7d92154387c2054,
  HeroD1d7adf5Aef54a119c6a442d7e998c00,
  Body34c34e3eD50f4c23Beea525c629e6c71,
  LinkedLogoC242da18F6e04ff692d4C2c01c32373a,
  TitleEde0952831eb426389125fd093180f6e,
  Title86062b17470c4aea8f9c3f38b5df9fe0,
  DefaultFooterF8d581a591154c5085eeAfc37a378687,
  MarketingCardsF8990c7223274d67B89f940721af1369,
  Hero0959c8e5Efba4b94A429B39a03dc6e69,
  FooterF4acfd3e1d8a4f408f14E981ee03ec82,
  AltA2bb1080F52b468dA0c1572c2f650631,
  QuoteB58ae1d2579d45d0A32315ad762a8798,
  HeaderBae76cd5Fc044a64Ae9258b52b21e83d,
  TitleD00b75f6120240608ec66d7a6f7349ce,
  PostSlug3b5388a44ddd41bb85099b0167386797,
  SignupPages5dcc8363E19e49c4B5dcDfe4e7ca80b2,
  MessagePages370e22e352c7417497137467752d9386,
  SocialLinksF57fd266127a4a5d96a8A59b60da24ce,
  SecondaryNavB3b129a6902a406f86cf68927b376610,
  ButtonClassA2d923d94429410985ae152a43ddbbd2,
  Title615490fcB25d4a62A0d2Ee127a090067,
  NavMenuSlug0727215e8b434d76B589Fed35dc9aba8,
  PostSlug51e16a96174341d49ed7C81cdaea689a,
  NavMenuSlugDf268efc334245a497754db12de3d6f8,
  ShortHeaderF4495d9988a3444381239aec8c5a8272,
  NavMenuSlugA16b7e4c9abc43479c63Cbfc54de75e1,
  Title99616b1bFdd346ce863aF36e62a2bc07,
  Logo75ea4596F011461b8d5d745169950e7a,
  Label67416c1cBb9e421cA453005b2b63613a,
  Class7e08cf83Dbd643b5Bc5b62ca9236ebf3,
  TitleD89dd62f91bb466082953c09ea503a17,
  PublicEventsPage2490953d1e1c4eaa8463C79c1b1f0788,
  SocialLinks054920e96e444043B1d205562afdbfde,
  HeaderEebc904e3f7840d5913a089032a75ce3,
  Groupflow567f446731694e279387015eb89faede,
  Footer1bc4c16332b4440dB1a6B7d9c48a96f7,
  Header87d6f91c301d43feA97c1bbaa96509cf,
  Title4b7de86111de4edd90782e760b8fd742,
  Class39045dbc7d094f1bAb6d7ae409638b34,
  SecondaryMarketingHeader147d6e17Cb6b41b8Bd4eCbfe705c794d,
  WelcomeBack7944c9f38c4e433dA4a2E444ca136a31,
  FooterBfc3f50e72384627B224C060ee1cd255,
  Header96582bb42a6c4c5897a72195687103d2,
  Copyright331f5e250d8949e6Afdd23637feb2e00,
  Header96250f54240545239b391c4d5a6a368c,
  Title41b2d9e98dd84e7e8413679825aa6d47,
  Href37608792A9ce4b7391c9A575b85302ef,
  DefaultHeaderTop4a32926b471e4599Bdd67bc45bae5a92,
  Navigation2b61fcae7e424d69B54aAb5bc1d5b791,
  Button8fb85f669f0544f5Bbd41102a423d038,
  FooterE48f3ad59b3047d698dcEd09f58efee6,
  Title5111cbc0E3ea4e6392d338e9a91ab0c6,
  HeaderTopB093bd3d917142bbB58a3fdcc1a09481,
  Label734b5853Dacb4993Ac0bAf63fd2f9033,
  Title57a6c466C5c945089f880205d8455ff1,
  SecondaryMarketingHero601b60e5E73b4c329943D29d8c6c845f,
  HeaderTop79c2e2e80ec548fbA76a678688a6a760,
  BackgroundImage29a4552eE4d94aa0B339743d2cff77f0,
  Footer9231017d85104c8c869f4b064de539f7,
  LinksF4153171Abc24b3684a2Deb959277fe9,
  Footer2d6e386744fa46388998E8dde15ff4c5,
  Title7ed60172A29f402cB83bD1ad656e5c69,
  NavMenuSlugF0553fb1Dc5f4c08A1b1E6954418f326,
  HomePageHero4da0a4e057164c019135E89d57833f02,
  HeaderC8726071174f4fd2881c6756de489d57,
  Header297058c8C6cd484bBfe13f2bf81f3471,
  HeaderTop4419b8d2Cf62448b814eBb2d050c4646,
  MarketingCards8692c80556e148eaBab3Eddf27fbf591,
  BodyF46df1d7A1804e53891a1ba6d6f96bc9,
  Url0da1a6478d3e4f43B20a61903aceedb8,
  NavMenuSlug3d296a080ec44a89B0a61faa60295646,
  PasswordResetPage73eeef80A427485f98b6789a8f20c266,
  HeaderDb3b583812ef41e6Bc8c80a358123c23,
  Navigation5bce5f1d4ecd454b8e242dfd60327fc7,
  Header3f5e2118A8864a54A787De03086710c5,
  Footer784c5ed2Fce8410789d5Cf14c68a7fb8,
  Header361ea67eDd9f49c39d1dA53629268dfa,
  LabelB0dfa95c65564195B4f00d85e8f4aa7a,
  NavMenuSlug630d15d1Fba94dda9370248109d7a8d3
}

export default Components;