const ROUTES = {
  "/": {
    "name": "HomePage19237ff2D33947be9716D662d01204ee",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages3d1f409a75f548fbA321D7f7558471c9",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage2a68cb94F2744efa9957108816c33759",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/events/public": {
    "name": "PublicEventsPage2490953d1e1c4eaa8463C79c1b1f0788",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageEe710927Fa1f4a178d4c4be409ee1dd5",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/login": {
    "name": "LoginPage5a170a1555a34e12AadeB1ac0c748027",
    "type": "HTML",
    "key": "login-page"
  },
  "/members/*": {
    "name": "MemberPages484230e399414899B6f59b7179913274",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages370e22e352c7417497137467752d9386",
    "type": "HTML",
    "key": "message-pages"
  },
  "/reset_password": {
    "name": "PasswordResetPage73eeef80A427485f98b6789a8f20c266",
    "type": "REFERENCE",
    "key": "password-reset-page"
  },
  "/resources": {
    "name": "ResourcesPage462a58771a9146f9A26cE0192043ca8b",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages5dcc8363E19e49c4B5dcDfe4e7ca80b2",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;